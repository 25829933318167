import React from "react"
import * as s from "./ThankYou.module.css";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";

const ThankYou = ({ formData, showCalendlyForm }) => {

  useCalendlyEventListener({
    // onProfilePageViewed: () => console.log("onProfilePageViewed"),
    // onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    // onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      let payload = {
        event: 'formSubmit',
        type: 'calendly',
        name: formData.name,
        email: formData.email,
      };
      window.dataLayer.push({ ...payload });
      // const requestOptions = {
      //   method: "POST",
      //   mode: "no-cors",
      //   headers: {
      //     "Content-Type": "application/json",
      //     'Accept': 'application/json',
      //     'Access-Control-Allow-Origin': '*',
      //   },
      //   body: JSON.stringify(payload),
      // };
      // try {
      //   fetch("https://hooks.zapier.com/hooks/catch/6831474/b0tr08s/", requestOptions);
      // } catch (error) {
      //   console.log(error)
      // }
    },
  });

  // console.log(process.env.CALENDLY_URL);
  //url="https://calendly.com/rinat-hairapetian/30min"
  return (
    <div className={`${s.thankYou} thank-you`}>
      <h3>Thank You</h3>
      {!showCalendlyForm && <>
        <h5>Your message has been received.<br /> We will update you shortly.</h5>
      </>}
      {!!showCalendlyForm && <>
        <h5>Feel free to schedule a call with us</h5>
        <InlineWidget prefill={{
          name: formData.name,
          email: formData.email,
        }}
        url={process.env.CALENDLY_URL} />
      </>}
    </div>
  )
}

export default ThankYou
