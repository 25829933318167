import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";

import * as s from "../assets/css/vacancy.module.css";

import { Widget } from '@typeform/embed-react'

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css";
import "../assets/css/@wordpress/block-library/build-style/theme.css";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ThankYou from "../components/ThankYou/ThankYou";

const VacancyTemplate = ({ data: { vacancy }, location }) => {
  const featuredImage = {
    data: vacancy.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: vacancy.featuredImage?.node?.alt || ``,
  }


  let initialQuizData = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  };


  const [formData, setFormData] = React.useState(initialQuizData);
  const [messageSent, setMessageSent] = React.useState(null);
  const [errors, setErrors] = React.useState([]);

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  }

  const submitForm = () => {
    if (isValidForm()) {
      setMessageSent(true);


      let payload = {
        event: 'formSubmit',
        type: 'vacancy-page',
        ...formData
      };
      // console.log(payload);
      window.dataLayer.push({ ...payload });

      const requestOptions = {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(payload),
      };
      try {
        fetch("https://hooks.zapier.com/hooks/catch/6831474/b0d194c/", requestOptions);
      } catch (error) {
        console.log(error)
      }
    }
  }

  const isValidForm = () => {
    let isValid = true;
    let newErrors = [];

    if (!formData?.firstName?.length) {
      isValid = false;
      newErrors.push('Please enter a First Name');
    }

    if (!formData?.lastName?.length) {
      isValid = false;
      newErrors.push('Please enter a Last Name');
    }

    if (!isEmailValid(formData?.email)) {
      isValid = false;
      newErrors.push('Please enter a valid Email');
    }



    if (!formData?.message?.length) {
      isValid = false;
      newErrors.push('Please enter a Message');
    }


    setErrors(newErrors);
    // isValid = false;
    return isValid;
  }

  const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  return (
    <Layout>
      <Seo
        title={vacancy.title}
        description={""}
        pageSeo={vacancy}
        location={location}
      />
      <section className={`${s.privacyPolicy} privacy-policy`}>
        <div className="container">
          <h1 itemProp="headline" className="text-center">{parse(vacancy.title)}</h1>
          {!!vacancy.content &&
            parse(vacancy.content)
          }
        </div>
      </section>
      <section className={`${s.form}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-ls-8 col-xl-8 col-xxl-6 d-none">
              {!messageSent &&
                <div className="row">
                  <div className="col-12">
                    <h2>Apply Form</h2>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={`${s.inputLabel}`}>
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        placeholder="Enter your first name" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={`${s.inputLabel}`}>
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        placeholder="Enter your last name" />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className={`${s.inputLabel} w-100`}>
                      <label htmlFor="email">Your Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter your email" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className={`${s.inputLabel}`}>
                      <label htmlFor="comp-name">Message</label>
                      <textarea
                        name={'message'}
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Enter your message" />

                    </div>
                  </div>
                  <div className="col-12">
                    <button className={`${s.formSubmit} `} onClick={submitForm}>Send</button>
                  </div>
                  <div className="col-12">
                    {errors?.length > 0 &&
                      <div className={`mt-3 d-flex flex-column`}
                        style={{ 'gap': '4px', 'maxWidth': 'max-content' }}>
                        {errors?.map((err, erri) => {
                          return <div className={`error-message`} key={`error-${erri}`}>{err}</div>
                        })}
                      </div>
                    }
                  </div>
                </div>
              }
              {messageSent && <ThankYou />}
            </div>
            <div className="col-12 col-xxl-6">
              <h2>Apply Form</h2>
              <Widget hidden={{ vacancy_name: vacancy.title }}
                id={process.env.TYPEFORM_FORM_ID}
                className={`${s.typeform} my-form`} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default VacancyTemplate

export const vacancyQuery = graphql`
  query VacancyById(
    $id: String!
  ) {
    vacancy: wpVacancy(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`
